input.input1 + .shadow-input1 {
  border-radius: 0.5rem;
}

textarea.input1 + .shadow-input1 {
  border-radius: 0.5rem;
}

.wrap-input1 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 10px;
}

.input1 {
  display: block;
  width: 100%;
  background: #e6e6e6;
  font-size: 15px;
  line-height: 1.5;
  color: f5f5f5;
}

.shadow-input1 {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: #42b1bc;
}

.input1:focus + .shadow-input1 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}
