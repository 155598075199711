

.full {
  border: none;
  padding: 0.6rem 1.1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.outline {
  padding: 0.6rem 1.1rem;
  background: transparent;
  border-radius: 0.5rem;
  font-size: 1rem;
}

.transformHover {
  transition: all 0.3s ease-in-out;
  margin: 0.5rem;
}
.transformHover:hover {
  transform: scale(1.06);
}

.main {
  width: 45%;
  min-height: 380px;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.service {
  /* background: rgb(121, 11, 224);; */
  /* padding: 30px; */
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  box-shadow: 0 0 20px -15px #030380;
  transition: transform 0.8s;
}

.service-logo {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: -70px auto 0;
  background: #42b1bc;
  border: 12px solid #fff;
}
.service-logo img {
  width: 100px;
  margin-top: 1px;
  transition: transform 1s;
}

.service h4 {
  height: 35px;
  width: 80%;
  background: #fff;
  margin: 30px auto;
  position: relative;
}

.service h4::after {
  content: "";
  width: 40px;
  height: 30px;
  background: linear-gradient(to right, #fff, #42b1bc);
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: -1;
}
.service h4::before {
  content: "";
  width: 40px;
  height: 30px;
  background: linear-gradient(to right, #42b1bc, #fff);
  position: absolute;
  left: -5px;
  bottom: -5px;
  z-index: -1;
}

.main:hover .service {
  transform: translateY(-50px);
}

.main:hover img {
  transform: rotate(360deg);
}

@media (max-width: 768px) {
  .main {
    width: 100%;
  }
}

.img-thumbnail:hover {
  box-shadow: 0 0 2px 1px #be43ba;
}

.carousel {
  margin: 30px auto;
}

.carousel .media {
  padding: 0 0 0 20px;
  margin-left: 20px;
  bottom: 0;
}
.avatar-caroussel {
  width: 75px;
  height: 75px;

  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
}
.carousel .testimonial {
  position: relative;
  background: #9b9b9b;
  max-height: 30;
  padding: 15px;
  margin: 0 0 20px 20px;
}
.carousel .testimonial::before,
.carousel .testimonial::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -20px;
}
.carousel .testimonial::before {
  width: 20px;
  height: 20px;
  background: #9b9b9b;
  box-shadow: inset 12px 0 13px rgba(0, 0, 0, 0.5);
}
.carousel .testimonial::after {
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.carousel .testimonial p {
  text-indent: 40px;
  line-height: 21px;
  margin: 0;
}
.carousel .testimonial p::before {
  content: "\201D";
  font-family: Arial, sans-serif;
  color: #fff;
  font-weight: bold;
  font-size: 68px;
  line-height: 70px;
  position: absolute;
  left: -25px;
  top: 0;
}

.fpvZQm {
  background-color: #42b1bc!important;
  box-shadow: 0 0 1px 3px #42b1bc80 !important;
}
.cYcnau:hover{
background-color:#42b1bc!important;
}

.box-shadow{
  box-shadow: -9px 11px 0px -5px rgba(37,172,167,0.42);
-webkit-box-shadow: -9px 11px 0px -5px rgba(37,172,167,0.42);
-moz-box-shadow: -9px 11px 0px -5px rgba(37,172,167,0.42);
}
.box-shadow-right{
box-shadow: 9px 11px 0px -5px rgba(37,172,167,0.42);
-webkit-box-shadow: 9px 11px 0px -5px rgba(37,172,167,0.42);
-moz-box-shadow: 9px 11px 0px -5px rgba(37,172,167,0.42);

  
  
}
.nav-box-shadow{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}


.wrap-left-img {
  position: relative;
}

.wrap-left-img img {
  border-radius: 0 0 0 20px;
  transform: skewY(-3deg);
}

.wrap-left-img::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 10px;
  right: -10px;
  left: 10px;
  border-radius: 0 0 0 20px;
  border: 2px solid #42b1bc;
  transform: skewY(-3deg);
}

.wrap-right-img {
  position: relative;
}

.wrap-right-img img {
  border-radius: 0 0 20px 0;
  transform: skewY(3deg);
}

.wrap-right-img::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 10px;
  right: -10px;
  left: 10px;
  border-radius: 0 0 20px 0;
  border: 2px solid #42b1bc;
  transform: skewY(3deg);
}

.wrap-right-purple-img {
  position: relative;
}

.wrap-right-purple-img img {
  border-radius: 0 0 20px 0;
  transform: skewY(3deg);
}

.wrap-right-purple-img::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 10px;
  right: -10px;
  left: 10px;
  border-radius: 0 0 20px 0;
  border: 2px solid #be43ba;
  transform: skewY(3deg);
}
.container-fluid{
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.834)
      url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
      no-repeat;
  z-index: 1;
}
.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}