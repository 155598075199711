@font-face {
  font-family: "brittanyt";
  src: url("../fonts/BrittanySignature.woff") format("woff");
}

@font-face {
  font-family: "brittanyt-LjyZ";
  src: url("../fonts/BrittanySignature-LjyZ.otf") format("otf");
}
@font-face {
  font-family: "brittanyt-LjyZ";
  src: url("../fonts/AmiriQuran-Regular.ttf") format("otf");
}

@font-face {
  font-family: "AmiriQuran-Regular";
  src: url("../fonts/AmiriQuran-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "wessam";
  src: url("../fonts/\(A\)\ Arslan\ Wessam\ A\ \(A\)\ Arslan\ Wessam\ A.ttf")
    format("ttf");
}
@font-face {
  font-family: "Merriweather-Regular";
  src: url("../fonts/Merriweather-Regular.ttf") format("ttf");
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
} */
