.rectangle-outline {
  position: absolute;
  width: 120px;

  height: 110%;
  box-sizing: border-box;
  border-bottom-right-radius: 15px;
  display: flex;
  flex-direction: column;
}

/* .img-wrap {
  border: solid #42b1bc 2px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-radius: 0 0 0 20px;
  transform: skewY(-3deg);
  z-index: 1;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;

} */


/* .parallelogramme-text-container {
  transform: skewX(-20deg);
  transform: skewY(-3deg);
} */
/* .parallelogramme-container {
  transform: skewX(20deg);
  transform: skewY(3deg);
  border-radius: 15px;
} */

.button-container {
  display: none;
}

.button-session:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.outline-text-green {
  color: white;
  background-color: white;
  text-shadow: -1px -1px 0 #42b1bc, 1px -1px 0 #42b1bc, -1px 1px 0 #42b1bc,
    1px 1px 0 #42b1bc;
}
.outline-text-purple {
  color: white;
  background-color: white;
  text-shadow: -1px -1px 0 #be43ba, 1px -1px 0 #be43ba, -1px 1px 0 #be43ba,
    1px 1px 0 #be43ba;
}
