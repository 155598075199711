@media screen and (max-width: 700px) {
    .display-none-small {
      display: none;
    }
  }

  @media screen and (min-width: 700px) {
    .display-none-big {
      display: none;
    }
  }
  @media screen and (max-width: 992px) {
    .display-none-992 {
      display: none;
    }
  }
  /* @media screen and (max-width: 427px) {
    .display-none-caroussel {
      display: none;
    }
  } */
  @media screen and (min-width: 768px) {
    .display-none-768 {
      display: none;
    }
  }