.white {
  color: #fff;
}

.bg-darkbleu {
  background-color: #20374e;
}

.green-palette {
  color: rgb(66, 177, 188);
}
.bg-green-palette {
  background-color: #42b1bc;
}
.purple-palette {
  color: #be43ba;
}
.bg-purple-palette {
  background: #be43ba;
}
.light-orange {
  color: #ffc771;
}
.bg-light-orange {
  background: #ffc771;
}
